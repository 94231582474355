

















import { Component, Prop, Vue } from "vue-property-decorator";
import Marketstat from "@/components/UIComponents/Marketstat.vue";
import noticeTab from "@/components/UIComponents/noticeTab.vue";

@Component({
  components: {
    Marketstat,
    noticeTab
  }
})
export default class aboveFooter extends Vue {}
