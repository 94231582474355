












































import { Component, Prop, Vue } from "vue-property-decorator";
import { CropVarietyStore, CompareCropStore } from "@/store/modules";

@Component
export default class Recentvarieties extends Vue {
  loading: boolean = false;
  get recentCrops() {
    return CropVarietyStore.RecentCropVarieties;
  }

  gotoDetailScreen(id: string, varietySlug: string, cropId: string, cropSlug: string) {
    this.$router
      .push({
        name: "DetailScreen",
        params: {
          cropSlug: cropSlug,
          varietySlug: varietySlug
        }
      })
      .catch(error => {});
    CompareCropStore.clearSelectedCrops();
  }

  async created() {
    this.loading = true;
    await CropVarietyStore.getRecentCropVarieties({ lang: this.$i18n.locale });
    this.loading = false;
  }
}
