













































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DemandSupplyStore } from "@/store/modules";
import moment from "moment";

@Component
export default class Marketstat extends Vue {
  activeName: string = "one";
  loading: boolean = false;

  get supplies() {
    return DemandSupplyStore.MarketStat.supply;
  }

  get demands() {
    return DemandSupplyStore.MarketStat.demand;
  }

  calculateMoment(date: string) {
    let newDate = new Date(date);
    return moment(newDate).fromNow();
  }

  async created() {
    this.loading = true;
    await DemandSupplyStore.getCurrentMarketStat();
    this.loading = false;
  }
}
