
























































import { Component, Prop, Vue } from "vue-property-decorator";
import { DemandSupplyStore } from "@/store/modules";
import moment from "moment";

@Component
export default class Marketstat extends Vue {
  data() {
    return {
      activeName: "one"
    };
  }

  get upcomingnotice() {
    return DemandSupplyStore.Notices.upcomingnotice;
  }

  get ongoingnotice() {
    return DemandSupplyStore.Notices.ongoingnotice;
  }

  handleClick(tab: any, event: any) {
    console.log(tab, event);
  }

  calculateMoment(date: string) {
    let newDate = new Date(date);
    return moment(newDate).fromNow();
  }

  created() {
    DemandSupplyStore.getNotices();
  }
}
