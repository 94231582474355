





































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DemandSupplyStore } from "../../store/modules";

@Component
export default class Cropstat extends Vue {
  selected: string = "Breeder";

  isHidden(key: string) {
    let isHidden: boolean = true;
    if (key == this.selected) {
      isHidden = false;
    }

    return isHidden;
  }

  get supplierStat() {
    return DemandSupplyStore.SupplierStat;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  getSeedTypeId(key: string) {
    let id: number = 0;
    let seed = this.seedTypes.filter(x => x.name.toLowerCase() == String(key).toLowerCase())[0];
    if (this.$i18n.locale == "ne") {
      seed = this.seedTypes.filter(x => x.nepname.toLowerCase() == String(key).toLowerCase())[0];
    }
    if (seed) {
      return seed.id;
    }
    return id;
  }

  async created() {
    DemandSupplyStore.getSupplierStat({ lang: this.$i18n.locale });
    await DemandSupplyStore.getAllSeedType();
    if (this.seedTypes.length > 0) {
      this.selected =
        this.$i18n.locale == "ne" ? this.seedTypes[0].nepname : this.seedTypes[0].name;
    }
  }
}
